import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BreadcrumbsService } from '../../core/breadcrumbs.service';
import { ScrollService } from '../../core/scroll.service';
import { LoginGuardService } from '../../api/login-guard.service';
import { LangService } from '../../core/lang.service';
import { RoutesService } from '../../api/routes.service';
import { AuthService, IUserInfo } from '../../api/auth.service';
import { AUTH_REG_ERROR, regErrorMsgToTransSlug } from '../../api/constants/registration-validation';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';


interface ICreate {
  invitationCode: string;
  email: string;
}

@Component({
  selector: 'view-account-link-invitation',
  templateUrl: './view-account-link-invitation.component.html',
  styleUrls: ['./view-account-link-invitation.component.scss']
})

export class ViewAccountLinkInvitationComponent implements OnInit {

  constructor(
    public lang: LangService, //
    private loginGuard: LoginGuardService, //
    private breadcrumbsService: BreadcrumbsService,
    private router: Router,
    private route: ActivatedRoute,
    private routes: RoutesService,
    private auth: AuthService,
    private scrollService: ScrollService,
  ) { }

  public breadcrumb = [];
  public isFormValidated: boolean;
  public isFormFailed: boolean;
  public formFailReason: string;
  public emailUsed: string;
  public isPasswordPolicyShowing: boolean;
  private passedInInvitationCode: string;
  private routeSub: Subscription;
  public AUTH_REG_ERROR = AUTH_REG_ERROR;
  public formGroup = new FormGroup({
    invitationCode: new FormControl(),
    emailAddress: new FormControl()
  });
  private userInfo: IUserInfo;
  subscription = new Subscription();

  isSubmitting: boolean;

  isRefreshingFailReason: boolean;
  ngOnInit() {
    this.loginGuard.activate();
    this.scrollService.scrollToTop();
    this.breadcrumb = [
      this.breadcrumbsService.TESTADMIN_LANDING(),
      this.breadcrumbsService._CURRENT('Accept Invitation', this.router.url), // need translation
    ];
    this.routeSub = this.route.params.subscribe( routeParams => {
      this.passedInInvitationCode = routeParams['invitationCode'];
      if (this.passedInInvitationCode) {
        this.formGroup.controls['invitationCode'].setValue(this.passedInInvitationCode);
      }
    });
    this.subscription.add(
      this.auth.user().subscribe(userInfo => this.userInfo = userInfo)
    );
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  submitForm() {
    this.isSubmitting = true;
    this.isFormFailed = false;
    this.createAccount()
      .then(res => {
        this.isFormValidated = true;
        this.scrollService.scrollToTop();
        this.isSubmitting = false;
      })
      .catch(err => {
        // console.warn('createAccount fail', err)
        this.isFormFailed = true;
        this.isSubmitting = false;
        this.formFailReason = regErrorMsgToTransSlug(err.message);
        this.refreshFailReason();
      });
  }
  private refreshFailReason() {
    this.isRefreshingFailReason = true;
    setTimeout(() => this.isRefreshingFailReason = false, 500);
  }


  private createAccount() {

    const invitationCode = this.formGroup.controls.invitationCode.value;
    const email = this.userInfo.email;
    const uid = this.userInfo.uid;

    if (!invitationCode) { return Promise.reject({ message: 'MISSING_INVITATION' }); }

    this.emailUsed = email;

    const req: ICreate = {
      invitationCode,
      email
    };

    return this.auth
      .apiPatch(
        this.routes.AUTH_TEST_ADMIN,
        uid,
        req
      );
  }

}
