import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WhitelabelService } from '../domain/whitelabel.service';
import { AuthScopeSetting, AuthScopeSettingsService } from './auth-scope-settings.service';

@Injectable({
  providedIn: 'root'
})
export class EditingDisabledService {

  private isEditingDisabled = new BehaviorSubject<boolean>(false);
  private isCurrQLocked = new BehaviorSubject<boolean>(false);
  private isCurrQTrackingChanges = new BehaviorSubject<boolean>(false);
  public isManualEditingEnabled:boolean;
  public isInEditor:boolean;
  
  constructor(
    private authScopeSettings: AuthScopeSettingsService,
    private whiteLabel: WhitelabelService,
  ) { 
  }

  setEditingDisabled(b:boolean) {
    this.isEditingDisabled.next(b);
  }

  isEditActivationPending(){
    return this.isManualEditApplicable() && this.isInEditor && !this.isManualEditingEnabled
  }
  isManualEditApplicable(){
    return this.whiteLabel.getSiteFlag('PREVENT_AUTO_EDIT')
  }

  setCurrQState(state: {isLocked:boolean, isTrackingChanges: boolean}){
    this.isCurrQLocked.next(state.isLocked);
    this.isCurrQTrackingChanges.next(state.isTrackingChanges);
  }

  getEditingDisabled() {
    return this.isEditingDisabled.getValue();
  }

  getCurrQLocked() {
    return this.isCurrQLocked.getValue();
  }

  getCurrQTrackingChanges() {
    return this.isCurrQTrackingChanges.getValue();
  }

  isReadOnly(ignoreCurrQLock: boolean = false, ignoreEditingDisabled: boolean = false) {
    if (this.isEditActivationPending() && !ignoreCurrQLock && !ignoreEditingDisabled){
      return true;
    }
    return (this.authScopeSettings.getSetting(AuthScopeSetting.DISABLE_EDITING) || 
    (!ignoreEditingDisabled && this.getEditingDisabled()) || 
    (!ignoreCurrQLock && this.getCurrQLocked()));
  }

  isSingularReadOnly() {
    return this.authScopeSettings.getSetting(AuthScopeSetting.DISABLE_EDITING_SINGULAR);
  }
}