import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RouteParamsService {
  private routeParams: any = {};
  private routeParams$ = new BehaviorSubject<any | null>(null);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { 
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route.snapshot.params;
       }))
      .subscribe(params => {
        this.routeParams$.next(params);
        return this.routeParams = params
      })
  }

  // Method to get the `group_id` parameter
  getGroupId(): Observable<string | null> {
    return this.routeParams$.pipe(
      map(params => {
        if (!params) {
          return null;
        }
        return params['group_id']
      })
    )
  }


  // Method to get the `lang` parameter
  getLang(): Observable<string | null> {
    return this.routeParams$.pipe(
      map(params => {
        if (!params) {
          return null;
        }
        return params['lang']
      })
    )
  }

  // Generic method to get any route parameter
  getParam(paramName: string):Observable<any | null> {
    return this.routeParams$.pipe(
      map(params => {
        if (!params) {
          return null;
        }
        return params[paramName]
      })
    )
  }
}
