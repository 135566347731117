<div class="page-body">
    <div>
      <header
      [breadcrumbPath]="breadcrumb"
      ></header>
      <div class="page-content is-fullpage" >
        <div class="form-content" *ngIf="!isFormValidated">
          <h2> Link current account </h2>
          <div class="form-instruction-major">
            Please check your email for your official invitation code before attempting to link account.
          </div>

          <div class="field">
            <label class="label"> <tra slug="create_acct_inv_code"></tra> </label>
            <div class="control">
              <input class="input" [formControl]="formGroup.controls.invitationCode">
            </div>
          </div>

          <hr/>          

          <div *ngIf="isFormFailed" class="notification is-warning">
              <!-- formFailReason: {{formFailReason}} -->
            <tra-md  *ngIf="!isRefreshingFailReason" [slug]="formFailReason"></tra-md>
          </div>

          <div>
            <button (click)="submitForm()" [disabled]="isSubmitting" class="button is-success is-large is-fullwidth">
              Link This Account
            </button>
          </div>

        </div>
        <div class="form-content" *ngIf="isFormValidated">
          <h2> Your account has been linked </h2>
        </div>
      </div>
    </div>
    <footer [hasLinks]="true"></footer>
  </div>
  