import * as _ from 'lodash';
import * as moment from 'moment-timezone';
import { Injectable } from '@angular/core';
import { RoutesService } from '../api/routes.service';
import { AuthService } from '../api/auth.service';
import { ITestSessionDashboardInfo, ITestSessionBookingInfo } from '../api/models/db/test-sessions.schema';
import { IAvailableSession, IBooking } from './demo-data.service';
import { BehaviorSubject } from 'rxjs';
import { LangService } from '../core/lang.service';
import { TimezoneService } from '../core/timezone.service';

export interface ISessionRestrictionPatch {
  isHidden: boolean,
  isAccessCodeProtected: boolean,
  accessCode:string
}

@Injectable({
  providedIn: 'root'
})
export class AvailableSessionsService {

  private _myTestSessions:IAvailableSession[];
  private myTestSessions:BehaviorSubject<IAvailableSession[]> = new BehaviorSubject(null);
  private lastRequest: { instit_group_id: number };
  
  constructor(
    private auth: AuthService,
    private routes: RoutesService,
    private lang: LangService,
    private timezone: TimezoneService,
    // cannot point to Inst as it would make a circular reference
  ) { }

  public sub(){
    return this.myTestSessions;
  }

  // isRefreshInProgress:boolean = true;
  public refresh = _.debounce(()=>{
    this.myTestSessions.next(null);
    if (this.lastRequest){
      this.loadAssociatedTestSessions(this.lastRequest.instit_group_id)
    }
  }, 500, {leading: true, trailing: false})

  public setInstitGroupId(instit_group_id:number){
    this.lastRequest = {instit_group_id};
  }

  private isSuperWatcher:boolean;
  public toggleSuperWatcher(state:boolean){
    this.isSuperWatcher = state;
  }


  private renderSessionFindApiRoute(){
    if (this.isSuperWatcher){
      return this.routes.TEST_ADMIN_TEST_SESSIONS_ALL;
    }
    else{
      return this.routes.TEST_ADMIN_TEST_SESSIONS_MY_UPCOMING;
    }
  }

  private renderSessionSingleApiRoute(){
    if (this.isSuperWatcher){
      return this.routes.TEST_ADMIN_TEST_SESSIONS_SINGLE;
    }
    else{
      return this.routes.TEST_ADMIN_TEST_SESSIONS_MY_SINGLE;
    }
  }

  private renderPriorSessionFindApiRoute(){
    if (this.isSuperWatcher){
      return this.routes.TEST_ADMIN_TEST_SESSIONS_PRIOR;
    }
    else{
      return this.routes.TEST_ADMIN_TEST_SESSIONS_MY_PRIOR;
    }
  }

  private constructPermissionsParams(query?:any){
    if (!this.lastRequest){ console.warn('availSess constructPermissionsParams early exit'); return; }
    const {instit_group_id} = this.lastRequest;
    return {
      query:{ 
        ... query,
        instit_group_id, 
      }
    }
  }

  // catch NO_ACTIVE_SESSION error
  public loadSingleSession(sessionId:number, isPrior?: boolean){
    const query = {};
    if(isPrior) query['is_prior'] = true;
    
    const params = this.constructPermissionsParams(query);
    return this.auth
      .apiGet(
        this.renderSessionSingleApiRoute(),
        sessionId,
        params
      )
      .then((testSession:ITestSessionDashboardInfo) => {
        // console.log('testSession', testSession)
        return this.sanitizeDbSession(testSession);
      })
      .catch((err: Error) => {
        console.log('loadSingleSession error', err);
        throw err;
      })
  }

  public loadAssociatedTestSessions(instit_group_id:number){
    this.setInstitGroupId(instit_group_id);
    return this.auth
      .apiFind(
        this.renderSessionFindApiRoute(),
        this.constructPermissionsParams()
      )
      .then((testSessions:ITestSessionDashboardInfo[]) => {
        this._myTestSessions = testSessions.map((testSession, i) => this.sanitizeDbSession(testSession, i))
        this.myTestSessions.next(this._myTestSessions);
        return this._myTestSessions;
      })
  }

  public loadPriorTestSessions(instit_group_id:number){
    this.setInstitGroupId(instit_group_id);
    return this.auth
      .apiFind(
        this.renderPriorSessionFindApiRoute(),
        this.constructPermissionsParams()
      );
  };

  private sanitizeDbSession(testSession:ITestSessionDashboardInfo, sessionIndex:number=-1){
    const dateTimeStart = moment(testSession.date_time_start);
    // const isStartingNow = dateTimeStart.diff(moment(), 'hours') < 12;
    const bookings = (testSession.bookings_list || []).map( this.sanitizeBookingEntry );
    const waitlist = (testSession.waitlist_list || []).map( this.sanitizeBookingEntry );
    const pendingRemoved = (testSession.pending_removed_list || []).map( this.sanitizeBookingEntry );
    if (dateTimeStart && dateTimeStart.diff(moment(), 'hours') < 12){
      testSession.isTestDay = true;
    }
    let testWindowTitle;
    try {
      const titles = JSON.parse(testSession.test_window_title);
      testWindowTitle = titles[this.lang.c()];
    }
    catch(e){}
    // console.log(testSession, testSession.isTestDay)
    return <IAvailableSession>{
      __isStartingSoon: testSession.isTestDay,
      __num: sessionIndex+1,
      id: testSession.id,
      testWindowId: testSession.test_window_id,
      test_session_group_id: testSession.test_session_group_id,
      testWindowTitle,
      room: testSession.room,
      campusBuilding: testSession.campus_building,
      address: testSession.address,
      city: testSession.city,
      invigLang:testSession.invigLang,
      province: testSession.province, 
      postalCode: testSession.postal_code,
      phoneNumber: testSession.phone_number,
      facultyId: testSession.instit_group_id, // TO DO : rename from facultyId to instGroupId
      delivery_format: testSession.delivery_format,
      dateTimeStart: this.timezone.moment(testSession.date_time_start),
      closedOn: this.timezone.moment(testSession.closed_on),
      cancelledOn: this.timezone.moment(testSession.cancelled_on),
      capacity: testSession.capacity,
      isAccessCodeEnabled: !!testSession.is_access_code_enabled,
      accessCode: testSession.access_code,
      isHidden: !!testSession.is_hidden,
      isClosed: !!testSession.is_closed,
      isCancelled: !!testSession.is_cancelled,
      isTestDay: testSession.isTestDay,
      status: testSession.status,
      inivigilatorId: testSession.uid,
      inivigilatorFirstName: testSession.first_name,
      inivigilatorLastName: testSession.last_name,
      inivigilatorEmailName: testSession.contact_email,
      invitationCode: testSession.invitation_code,
      videostream_link: testSession.videostream_link,
      videostream_password: testSession.videostream_password,
      bookings,
      waitlist,
      pendingRemoved,
      bookingsCount: bookings.length || testSession.bookings,
      waitlistCount: waitlist.length || testSession.waitlist,
      time_ext_m: testSession.time_ext_m,
      test_window_info: testSession.test_window_info,
      is_paused: !!testSession.is_paused,
      is_closed: !!testSession.is_closed,
      is_cancelled: !!testSession.is_cancelled,
      is_mobile_tether: !!testSession.is_mobile_tether,
      is_video_conference: !!testSession.is_video_conference,
      custom_booking_buffer_d: testSession.custom_booking_buffer_d,
      twtdar_long_name: testSession.twtdar_long_name,
      twtdar_id: testSession.twtdar_id,
      is_soft_lock_disabled: testSession.is_soft_lock_disabled,
      printConfigs: testSession.printConfigs,
      test_duration: testSession.test_duration
    }
  }

  constructCandidateAddress(booking: ITestSessionBookingInfo) {
    // check if each params exist
    const street = booking.street ? booking.street + ', \n' : '';
    const city = booking.city ? booking.city + ', ' : '';
    const state = booking.state ? booking.state + ', ' : '';
    const zip = booking.zip ? '\n'+ booking.zip : '';
    return street+city+state+zip;
  }

  sanitizeBookingEntry = (booking:ITestSessionBookingInfo) : IBooking => {
    const firstName = booking.first_name;
    const lastName = booking.last_name;
    const langReq = booking.lang_req;
    return {
      name: firstName+' '+lastName,
      firstName,
      lastName,
      cand_address: this.constructCandidateAddress(booking),
      langReq,
      nameSortable: lastName+firstName,
      timestamp: moment(booking.created_on),
      uid: booking.uid,
      email: booking.contact_email,
      reqTransferTimestamp: booking.reqTransferTimestamp,
      delivery_format: booking.testSessionInfo ? booking.testSessionInfo.delivery_format : '',
      is_paused: booking.is_paused,
      is_soft_lock_disabled: booking.is_soft_lock_disabled,
      is_closed: booking.is_closed,
      role_type: booking.role_type,
      access_code: booking.access_code,
      id_number: booking.id_number,
      phone_number: booking.phone_number,
      print_configs: this.getPrintConfigs(booking),
      form_code: booking.form_code || '',
      isAgeExempted: !!booking.isAgeExempted,
      isRemoved: !!booking.is_removed,
    }
  }

  getPrintConfigs(booking:ITestSessionBookingInfo) {
    return booking.print_configs ? booking.print_configs.print_configs : '';
  }

  renderInvigDisplay(firstName:string, lastName:string, email?:string){
    let str = firstName;
    if (lastName){
      str = str + ' ' + lastName
    }
    if (email){
      str = str + ' ('+email+')';
    }
    return str;
  }
  
  renderInvigDisplayFromSession(session:IAvailableSession, isEmailIncl:boolean=true){
    if (session){
      const firstName = session.inivigilatorFirstName;
      const lastName = session.inivigilatorLastName;
      if (!isEmailIncl){
        return this.renderInvigDisplay(firstName, lastName);
      }
      const email = session.inivigilatorEmailName;
      return this.renderInvigDisplay(firstName, lastName, email);
    }
  }

  getSessionById(sessionId:number){
    for (let i=0; i<this._myTestSessions.length; i++){
      let session = this._myTestSessions[i];
      if (session.id === sessionId){
        return session;
      }
    }
  }

  patchSessionVideostreamLink(sessionId: number, videostream_link: string, videostream_password: string) {
    return this.auth.apiPatch(
      this.routes.TEST_ADMIN_TEST_SESSION_VIDEOSTREAM_LINK,
      sessionId, 
      {
        videostream_link,
        videostream_password
      }
    )
  }
  
  patchSessionRestriction(sessionId:number, formData){
    return this.auth
      .apiPatch(
        this.routes.TEST_ADMIN_TEST_SESSION_RESTRICTIONS, 
        sessionId, 
        {
          ... formData,
        },
        this.constructPermissionsParams()
      )
  }

  patchSessionCapacity(sessionId:number, newCapacity:number){
    return this.auth
      .apiPatch(
        this.routes.TEST_ADMIN_TEST_SESSION_CAPACITY, 
        sessionId, 
        {
          newCapacity,
        },
        this.constructPermissionsParams()
      )
  }

  patchSessionStatus(sessionId:number, newStatus:string){
    return this.auth
      .apiPatch(
        this.routes.TEST_ADMIN_TEST_SESSION_STATUS_UPDATE, 
        sessionId, 
        {
          newStatus,
        },
        this.constructPermissionsParams()
      )
  }


}
